/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import * as r from 'runtypes';
import { AboriginalTorresSchema, ChronicConditionsSchema, DVACardColorSchema, GenderSchema } from '../../constants';
import {
    DateSchema,
    EmailAddressSchema,
    IdEntitySchema,
    MedicalProfilePractitionerSchema,
    PersonalNameSchema,
    PhoneNumberSchema,
} from '..';
import {
    validateEmergencyContactRelationship,
    validateMedicalSummary,
    validateMedicareCardNumber,
    validateNumbersOnly,
} from '../../validator';
import { ResidentialAddressSchema } from '../address';
import { maybe } from '../../utils/runtypes';

export const MedicalSummarySchema = r.String.withConstraint(validateMedicalSummary);

export const MedicareCardSchema = r.Record({
    ref_number: r.String.withConstraint(validateNumbersOnly),
    card_number: r.String.withConstraint(validateMedicareCardNumber),
});
export type MedicareCard = r.Static<typeof MedicareCardSchema>;

export const EmergencyContactSchema = r.Record({
    mobile: PhoneNumberSchema,
    name: PersonalNameSchema,
    relationship: r.String.withConstraint(validateEmergencyContactRelationship),
});
export type EmergencyContact = r.Static<typeof EmergencyContactSchema>;

const DepartmentOfVeteranAffairsCardSchema = r.Record({
    card_number: r.String,
    color: DVACardColorSchema,
});
export type DepartmentOfVeteranAffairsCard = r.Static<typeof DepartmentOfVeteranAffairsCardSchema>;

export const MedicalProfileSchema = r
    .Record({
        gender: GenderSchema,
        phone: PhoneNumberSchema,
        date_of_birth: DateSchema,
        last_name: PersonalNameSchema,
        first_name: PersonalNameSchema,
        concession_card: maybe(r.String),
        address: ResidentialAddressSchema,
        allergies: maybe(MedicalSummarySchema),
        medicare_card: maybe(MedicareCardSchema),
        aboriginal_torres: AboriginalTorresSchema,
        emergency_contact: EmergencyContactSchema,
        medical_history: maybe(MedicalSummarySchema),
        individual_health_identifier: maybe(r.String),
        current_medication: maybe(MedicalSummarySchema),
        individual_healthcare_identifier: maybe(r.String),
        chronic_conditions: r.Array(ChronicConditionsSchema),
        department_of_veteran_affairs_card: maybe(DepartmentOfVeteranAffairsCardSchema),
    })
    .extend(MedicalProfilePractitionerSchema.fields);
export type MedicalProfile = r.Static<typeof MedicalProfileSchema>;

export const MedicalProfileResourceSchema = MedicalProfileSchema.extend(IdEntitySchema.fields);
export type MedicalProfileResource = r.Static<typeof MedicalProfileResourceSchema>;

export const GetMedicalProfilesResponseSchema = r.Array(MedicalProfileResourceSchema);

export const MedicalProfileDatabaseRecordSchema = r
    .Record({
        id: r.Number,
        reference_id: r.String,
        email: EmailAddressSchema,
    })
    .extend(MedicalProfileSchema.fields);
export type MedicalProfileDatabaseRecord = r.Static<typeof MedicalProfileDatabaseRecordSchema>;
