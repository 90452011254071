/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { FC } from 'react';
import { StyledLoadingPlaceholder, StyledSpinner } from './style';

export const FullScreenLoadingPlaceholder: FC = () => {
    return (
        <StyledLoadingPlaceholder>
            <StyledSpinner />
        </StyledLoadingPlaceholder>
    );
};
