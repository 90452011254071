/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import styled, { css } from 'styled-components';
import { StyledButton } from '../shared/Buttons/Button/style';

export const StyledDashboardSectionIcon = styled.div`
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 4px;
    position: relative;
    background: ${({ theme }) => theme.BrandPrimary};

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 34px;
        height: 34px;
        color: ${({ theme }) => theme.BrandWhite};
    }
`;

export const StyledPortalContentWrapper = styled.div`
    position: relative;
    padding-bottom: 80px;
`;

export const StyledGroupCell = styled.div`
    width: 100%;
    position: relative;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
`;

export const StyledModalContent = styled.div`
    padding: 24px;
    overflow-y: auto;
    position: relative;
    height: calc(100% - 60px);
`;

export const StyledModalHeaderButton = styled.button`
    width: 34px;
    height: 34px;
    outline: none;
    margin-left: auto;
    border-radius: 4px;
    transition: all 0.25s;
    color: ${({ theme }) => theme.TextPrimary};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.BorderSecondary};

    svg {
        width: 20px;
        height: 20px;
    }

    &:hover {
        color: ${({ theme }) => theme.ErrorPrimary};
    }
`;

export const StyledModalHeaderIcon = styled.div`
    svg {
        width: 24px;
        height: 24px;
        color: ${({ theme }) => theme.TextSecondary};
    }

    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledModalHeaderTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${({ theme }) => theme.TextPrimary};
`;

export const StyledModalHeader = styled.div`
    width: 100%;
    height: 60px;
    padding: 0 24px;
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.BorderPrimary};

    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const StyledModalFooter = styled.div`
    gap: 24px;
    width: 100%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${StyledButton} {
        width: 100%;
    }
`;

export const StyledModalContainer = styled.div<{ miniContainer?: boolean }>`
    width: 600px;
    height: 740px;
    position: relative;

    ${({ miniContainer }) =>
        miniContainer
            ? css`
                  width: 500px;
                  height: fit-content;
              `
            : css`
                  width: 520px;
                  height: 660px;
              `}

    @media (max-width: 540px) {
        width: 100%;
        height: 100%;
    }
`;

export const StyledDashboardSectionDescription = styled.p`
    font-size: 16px;
    max-width: 860px;
    line-height: 26px;
    color: ${({ theme }) => theme.TextSecondary};

    margin-top: 12px;
`;

export const StyledDashboardSectionHeaderOptions = styled.div`
    position: relative;
    margin-left: auto;

    ${StyledButton} {
        padding: 0 16px;
    }
`;

export const StyledDashboardSectionHeader = styled.div`
    left: 0;
    width: 100%;
    z-index: 200;
    padding: 0 24px;
    position: sticky;
    top: ${80}px;
    height: ${80}px;
    background: ${({ theme }) => theme.BackgroundPrimary};
    border-bottom: 1px solid ${({ theme }) => theme.BorderPrimary};

    gap: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
