/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export enum SkincareService {
    SkincareClinic = 'Skincare Clinic',
}
export const SkincareServiceTitleSchema = runtypeFromEnum(SkincareService);
