/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { ADDRESS_STREET_LIMIT, ADDRESS_POST_CODE_LIMIT, ADDRESS_CITY_LIMIT } from '../constants';

export function validateAddressCity(suburb: string) {
    return suburb.length <= ADDRESS_CITY_LIMIT || 'Invalid address city value.';
}

export function validateAddressStreet(street: string) {
    return street.length <= ADDRESS_STREET_LIMIT || 'Invalid residential address value.';
}

export function validateAddressPostcode(postCode: string) {
    return postCode.length <= ADDRESS_POST_CODE_LIMIT || 'Invalid address post code value.';
}
