/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { MEDICAL_PROFILES_LIMIT, PASSWORD_MAX_CHARACTERS, PASSWORD_MIN_CHARACTERS } from '../constants';
import { MedicalProfile } from '../types/medical/profile';

export function validateUserPassword(password: string) {
    const passwordCount = password.trim().length;
    // TODO: Ensure password includes a special character
    return (
        (passwordCount >= PASSWORD_MIN_CHARACTERS && passwordCount <= PASSWORD_MAX_CHARACTERS) ||
        `Invalid account password. A password should have between ${PASSWORD_MIN_CHARACTERS} and ${PASSWORD_MAX_CHARACTERS} characters.`
    );
}

export function validateUserMedicalProfiles(profiles: MedicalProfile[]) {
    return (
        profiles.length <= MEDICAL_PROFILES_LIMIT ||
        `Invalid user medical profiles. Medical profiles cannot exceed ${MEDICAL_PROFILES_LIMIT} entries.`
    );
}
