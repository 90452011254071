/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { CONSULTATION_NOTE_LENGTH } from '../constants';

export function validateConsultationNote(note: string) {
    const noteCount = note.trim().length;
    return noteCount < CONSULTATION_NOTE_LENGTH || 'Invalid consultation note.';
}
