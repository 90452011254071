/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

export enum SnackbarTypes {
    INFO = 'INFO',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

export enum ResourceSessionStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN PROGRESS',
}

export const CONSULTATION_NOTE_TEMPLATE = `History of presenting complaint:

Relevant PMHx:

Impression:

Plan:
`;

export interface FrequentlyAskedQuestion {
    question: string;
    answer: string;
}

export enum InputFieldError {
    Email = 'Email',
    Phone = 'Phone',
    Required = 'Required',
    MinLength = 'Min Length',
    MaxLength = 'Max Length',
    WhitespaceOnly = 'Whitespace Only',
    NoWhitespaceAllowed = 'No Whitespace Allowed',
}
