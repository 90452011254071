/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../utils/runtypes';

// Invoice table records request limit
export const INVOICE_RECORDS_LIMIT = 10;

export enum InvoicePaymentStatus {
    Paid = 'Paid',
    Failed = 'Failed',
    Pending = 'Pending',
    Refunded = 'Refunded',
    Cancelled = 'Cancelled',
}
export const InvoicePaymentStatusSchema = runtypeFromEnum(InvoicePaymentStatus);
