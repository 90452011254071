/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 *
 * Description:
 * Styling provider for button components
 */

import { css } from 'styled-components';

export enum ButtonType {
    Primary = 'Primary',
    Special = 'Special',
    Light = 'Light',
    White = 'White',
    Secondary = 'Secondary',
    Danger = 'Danger',
}

export interface CommonButtonProps {
    disabled?: boolean;
    variation: ButtonType;
}

const StyledButtonBaseCss = css`
    gap: 12px;
    height: 50px;
    cursor: pointer;
    font-size: 14px;
    padding: 0 24px;
    min-width: 120px;
    font-weight: 600;
    position: relative;
    border-radius: 8px;
    transition: all 0.25s;

    line-height: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    white-space: nowrap;
    text-overflow: ellipsis;

    svg {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
    }
`;

export const StyledPrimaryButtonCss = css`
    ${StyledButtonBaseCss};
    color: ${({ theme }) => theme.BrandWhite};
    background: ${({ theme }) => theme.BrandPrimary};

    &:hover {
        background: ${({ theme }) => theme.TextPrimary};
    }
`;

export const StyledSpecialButtonCss = css`
    ${StyledButtonBaseCss};
    color: ${({ theme }) => theme.BrandPrimary};
    background: ${({ theme }) => theme.BluePrimary};

    &:hover {
        background: ${({ theme }) => theme.BrandAccent};
    }
`;

export const StyledDangerButtonCss = css`
    ${StyledButtonBaseCss};
    color: ${({ theme }) => theme.BackgroundPrimary};
    background: ${({ theme }) => theme.ErrorPrimary};

    &:hover {
        background: ${({ theme }) => theme.ErrorPrimary};
    }
`;

export const StyledSecondaryButtonCss = css`
    ${StyledButtonBaseCss};
    color: ${({ theme }) => theme.BackgroundPrimary};
    background: ${({ theme }) => theme.TextPrimary};
`;

export const StyledLightButtonCss = css`
    ${StyledButtonBaseCss};
    color: ${({ theme }) => theme.TextPrimary};
    background: ${({ theme }) => theme.BackgroundPrimary};
    border: 1px solid ${({ theme }) => theme.BorderPrimary};

    &:hover {
        color: ${({ theme }) => theme.BrandPrimary};
        border-color: ${({ theme }) => theme.BrandPrimary};
    }
`;

export const StyledWhiteButtonCss = css`
    ${StyledButtonBaseCss};
    color: ${({ theme }) => theme.TextPrimary};
    background: ${({ theme }) => theme.BackgroundPrimary};

    &:hover {
        background: ${({ theme }) => theme.BackgroundSecondary};
    }
`;
