/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import {
    GetMedicalProfilesResponseSchema,
    MedicalProfileResourceSchema,
    MedicalProfileSchema,
} from '../../types/medical/profile';
import { EndpointSync, HttpMethod } from '../../utils/endpointSync';

export const GetMedicalProfilesEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/',
    responseValidator: GetMedicalProfilesResponseSchema,
});

export const CreateMedicalProfileEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/create',
    requestValidator: MedicalProfileSchema,
    responseValidator: MedicalProfileResourceSchema,
});

export const CreateOnboardingMedicalProfileEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/create/onboarding',
    requestValidator: MedicalProfileSchema,
});

export const UpdateMedicalProfileEndpoint = new EndpointSync({
    method: HttpMethod.PATCH,
    path: '/:medical_profile_id',
    requestValidator: MedicalProfileSchema,
});

export const DoctorUpdateMedicalProfileEndpoint = new EndpointSync({
    method: HttpMethod.PATCH,
    path: '/doctor/:medicalProfileId',
    requestValidator: MedicalProfileSchema,
});

export const DeleteMedicalProfileEndpoint = new EndpointSync({
    method: HttpMethod.DELETE,
    path: '/:medical_profile_id',
});
