/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export enum MedicalCertificatesTypes {
    SingleDayCertificateForWork = 'Single-day certificate for work',
    SingleDayCertificateForSchoolOrUniversity = 'Single-day certificate for school or university',
    SingleDayCertificateForCarersLeave = "Single-day certificate for carer's Leave",
    MultiDayCertificate = 'Multiple-day certificate',
}
export const MedicalCertificateTypesSchema = runtypeFromEnum(MedicalCertificatesTypes);

export enum MedicalCertificateClassification {
    Work = 'Work',
    School = 'School',
    University = 'University',
    Carer = 'Carer',
}
export const MedicalCertificateClassificationSchema = runtypeFromEnum(MedicalCertificateClassification);
export interface MedicalCertificateCategory {
    urlSlug: string;
    description: string;
    title: MedicalCertificatesTypes;
}

export type MedicalCertificateCategoryTypes = {
    [key in MedicalCertificatesTypes]: MedicalCertificateCategory;
};

export const MedicalCertificateServiceTitleSchema = MedicalCertificateTypesSchema;

export enum MedicalCertificateRequestReason {
    ColdOrFlue = 'Common cold or flu',
    HeadacheOrMigraine = 'Headache or migraine',
    LowBackPain = 'Low back pain',
    PeriodPain = 'Period pain',
    DentalPain = 'Dental pain',
    MentalHealthProblem = 'Mental health problem',
    FoodPoisoning = 'Food poisoning or stomach upset',
    HayFeverOrAllergies = 'Hay fever or allergies',
    Other = 'Other',
}
