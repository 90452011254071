/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { DigitalConsultationSchema } from '.';
import { ServiceRequestType } from '../constants/checkout';
import { SkincareServiceTitleSchema } from '../constants/services/skincare';

export const SkincareClinicRequestSchema = r.Record({
    title: SkincareServiceTitleSchema,
    consultations: r.Array(DigitalConsultationSchema),
    category: r.Literal(ServiceRequestType.SkincareClinic),
});
export type SkincareClinicRequest = r.Static<typeof SkincareClinicRequestSchema>;
