/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { AnyAction, Middleware, ThunkDispatch, combineReducers, configureStore, isRejected } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { setAutoFreeze } from 'immer';
import { createReduxHistoryContext } from 'redux-first-history';
import { persistStore } from 'redux-persist';
import { infirmaryReducer } from './redux/infirmary';
import { eventReducer } from './redux/event';
import { platformReducer } from './redux/platform';
import { doctorReducer } from './redux/doctor';
import { captureMessage } from './tools/sentry';
import { snackbarReducer } from './redux/snackbar';
import { consultationReducer } from './redux/consultation';
import { prescriptionReducer } from './redux/prescription';
import { articlesReducer } from './redux/articles';

const customMiddleware: Middleware = () => (next) => (action: AnyAction) => {
    // Handle rejected thunks except for session verifications.
    if (isRejected(action) && !action.type.endsWith('/doctor/verify/session')) {
        captureMessage('Error in async thunk', { extra: action });
    }

    return next(action);
};

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const rootReducer = combineReducers({
    // Router reducer.
    router: routerReducer,

    // Entity reducers.
    event: eventReducer,
    doctor: doctorReducer,
    store: infirmaryReducer,
    articles: articlesReducer,
    snackbar: snackbarReducer,
    platform: platformReducer,
    infirmary: infirmaryReducer,
    prescription: prescriptionReducer,
    consultation: consultationReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Triggers on all kinds of Map & Set store operations.
            immutableCheck: false, // Avoid crushing the app with complex selectors use.
        }).concat([routerMiddleware, customMiddleware]),
    devTools: true, // use this to disable in production; process.env.NODE_ENV !== 'production'
});

export const history = createReduxHistory(store);

// Create the store persister which stores it in local storage by default
export const persistor = persistStore(store);

// Type of the store state.
export type RootState = ReturnType<typeof store.getState>;

// Disable auto freeze
setAutoFreeze(false);
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;

export type AppDispatch = typeof store.dispatch;
