/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { INFIRMARY_MANAGER_PATH, MEDICAL_PROFILE_MANAGER_PATH } from '../../../api/endpoints';
import { DoctorUpdateMedicalProfileEndpoint } from '../../../api/endpoints/medical/profile';
import {
    ApproveAppScriptEndpoint,
    CheckInfirmaryPendingRequestEndpoint,
    CreateInfirmaryRequestFlagEndpoint,
    ExportPatientERxPadRecordEndpoint,
    GetInfirmaryHistoryEndpoint,
    GetInfirmaryMedicationEndpoint,
    GetInfirmaryRequestsEndpoint,
    RejectConsultationEndpoint,
    ResendAppScriptEndpoint,
    SendPatientConsultationNotificationEndpoint,
} from '../../../api/endpoints/infirmary';
import { MedicalRequestFlag, MedicalRequestNote } from '../../../api/types/medical/request';
import { MedicalProfile } from '../../../api/types/medical/profile';
import { endpointFetch } from '../tools/fetch';
import { RejectConsultation } from '../../../api/types/infirmary';
import { PatientNotificationType } from '../../../api/constants';

export function getInfirmaryMedicalRequests() {
    return endpointFetch(GetInfirmaryRequestsEndpoint, { scope: INFIRMARY_MANAGER_PATH });
}

export function getInfirmaryHistoryRequests() {
    return endpointFetch(GetInfirmaryHistoryEndpoint, { scope: INFIRMARY_MANAGER_PATH });
}

export function getInfirmaryMedicationRequests() {
    return endpointFetch(GetInfirmaryMedicationEndpoint, { scope: INFIRMARY_MANAGER_PATH });
}

export function checkPendingInfirmaryConsultation() {
    return endpointFetch(CheckInfirmaryPendingRequestEndpoint, { scope: INFIRMARY_MANAGER_PATH });
}

export function createInfirmaryMedicalRequestFlag(body: MedicalRequestFlag, consultationId: string) {
    return endpointFetch(CreateInfirmaryRequestFlagEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        body,
        params: {
            consultationId,
        },
    });
}

export function updateMedicalProfile(medicalProfileId: string, body: MedicalProfile) {
    return endpointFetch(DoctorUpdateMedicalProfileEndpoint, {
        scope: MEDICAL_PROFILE_MANAGER_PATH,
        body,
        params: {
            medicalProfileId,
        },
    });
}

export function approvalMedicalRequestAppScript(requestId: string, body: MedicalRequestNote) {
    return endpointFetch(ApproveAppScriptEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
        body,
    });
}

export function resendMedicalRequestAppScriptLetter(requestId: string) {
    return endpointFetch(ResendAppScriptEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
    });
}

export function rejectMedicalRequestConsultation(requestId: string, body: RejectConsultation) {
    return endpointFetch(RejectConsultationEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        body,
        params: {
            requestId,
        },
    });
}

export function exportERxPadPatientProfiles(body: string[]) {
    return endpointFetch(ExportPatientERxPadRecordEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        body,
    });
}

export function sendPatientConsultationNotification(
    medicalRequestId: string,
    notificationType: PatientNotificationType,
) {
    return endpointFetch(SendPatientConsultationNotificationEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            medicalRequestId,
            notificationType: notificationType.toLowerCase(),
        },
    });
}
