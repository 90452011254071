/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export enum MedicalHistoryStatus {
    Pending = 'Pending',
    Cancelled = 'Cancelled',
    Completed = 'Completed',
}
export const MedicalHistoryStatusSchema = runtypeFromEnum(MedicalHistoryStatus);
