/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import {
    COMMON_RESTFUL_ERROR_MESSAGE,
    ConsultationUploadCategory,
    PatientNotificationType,
} from '../../../../api/constants';
import {
    InfirmaryTakeConsultationResponse,
    CancelConsultationRequest,
    MedicalCertificateConsultationRequest,
    ConsultationScriptResponse,
    DoctorLetterConsultationRequest,
    PathologyTestConsultationRequest,
    SleepStudyRequestConsultation,
    MentalHealthCareConsultationRequest,
    SpecialistReferralConsultationRequest,
    MedicalImagingOrRadiologyConsultationRequest,
    InfirmaryConsultationRedirectSchema,
} from '../../../../api/types/infirmary';
import { SnackbarTypes } from '../../../../common/types';
import { InfirmaryDashboardRoutes } from '../../routes';
import { handleError, FetchError } from '../../tools/fetch';
import { setSnackbarMessage } from '../snackbar';
import {
    cancelConsultationMedicalRequest,
    createInfirmaryMedicalRequestNote,
    finalizeConsultationMedicalRequest,
    getHistoryConsultationMedicalRequest,
    sendConsultationDoctorLetterScript,
    sendConsultationMedicalCertificateScript,
    sendConsultationMedicalImagingOrRadiologyScript,
    sendConsultationMentalHealthCareScript,
    sendConsultationPathologyTestScript,
    sendConsultationSleepStudyScript,
    sendConsultationSpecialistReferralScript,
    takeConsultationMedicalRequest,
    updateConsultationDocumentViewState,
    uploadConsultationDocuments,
} from '../../fetch/consultation';
import { captureMessage } from '../../tools/sentry';
import { MedicalProfileResource, MedicalProfile } from '../../../../api/types/medical/profile';
import { sendPatientConsultationNotification, updateMedicalProfile } from '../../fetch/infirmary';
import { MedicalRequestNote, MedicalRequestNoteResource } from '../../../../api/types/medical/request';
import { ConsultationResourceFile } from '../../../../api/types/file';
import { RootState } from '../../store';
import { ConsultationNotification } from '../../../../api/types';

export const sendPatientConsultationNotificationThunk = createAsyncThunk<
    ConsultationNotification,
    {
        medicalRequestId: string;
        notificationType: PatientNotificationType;
        consultationTitle: string;
        patientName: string;
    }
>(
    'consultation/patient/notification',
    async ({ medicalRequestId, notificationType, patientName, consultationTitle }, { dispatch, rejectWithValue }) => {
        try {
            const notification = await sendPatientConsultationNotification(medicalRequestId, notificationType);

            dispatch(
                setSnackbarMessage({
                    message: `You have successfully sent an SMS and email notification to ${patientName} for the ${consultationTitle} consultation.`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );

            return notification;
        } catch (error) {
            console.log(
                'Notification Error: ',
                error,
                medicalRequestId,
                notificationType,
                patientName,
                consultationTitle,
            );
            const response = await handleError(error as FetchError);

            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            return rejectWithValue(response?.reason);
        }
    },
);

export const takeConsultationRequestThunk = createAsyncThunk<InfirmaryTakeConsultationResponse, string>(
    'consultation/take/request',
    async (consultationId, { dispatch, rejectWithValue }) => {
        try {
            return await takeConsultationMedicalRequest(consultationId);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            captureMessage(`[CONSULTATION INFIRMARY] Error taking consultation. Error: ${error}`);
            return rejectWithValue(response?.status);
        }
    },
);

export const getHistoryConsultationRequestThunk = createAsyncThunk<InfirmaryTakeConsultationResponse, string>(
    'consultation/history/request',
    async (consultationId, { dispatch, rejectWithValue }) => {
        try {
            return await getHistoryConsultationMedicalRequest(consultationId);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            captureMessage(
                `[CONSULTATION HISTORY] Error getting history medical request consultation. Error: ${error}`,
            );
            return rejectWithValue(response?.status);
        }
    },
);

export const finalizeConsultationRequestThunk = createAsyncThunk<
    void,
    { patientName: string; requestId: string; note: string }
>('consultation/finalize/request', async ({ requestId, note, patientName }, { dispatch, rejectWithValue }) => {
    try {
        await finalizeConsultationMedicalRequest(requestId, { note });
        dispatch(
            setSnackbarMessage({
                message: `You have successfully completed the consultation for ${patientName}.`,
                type: SnackbarTypes.SUCCESS,
            }),
        );
        dispatch(push(`/${InfirmaryDashboardRoutes.Patients}`));
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                type: SnackbarTypes.ERROR,
            }),
        );

        captureMessage(`[CONSULTATION INFIRMARY] Error finalizing consultation. Error: ${error}`);
        return rejectWithValue(response?.status);
    }
});

export const cancelConsultationRequestThunk = createAsyncThunk<
    void,
    { consultationId: string; request: CancelConsultationRequest }
>('consultation/take/cancel', async ({ consultationId, request }, { dispatch, rejectWithValue }) => {
    try {
        await cancelConsultationMedicalRequest(consultationId, request);
        dispatch(push(`/${InfirmaryDashboardRoutes.Patients}`));
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                type: SnackbarTypes.ERROR,
            }),
        );

        captureMessage(`[CONSULTATION INFIRMARY] Error cancelling consultation. Error: ${error}`);
        return rejectWithValue(response?.status);
    }
});

export const sendConsultationMedicalCertificateThunk = createAsyncThunk<
    ConsultationScriptResponse,
    { referenceId: string; body: MedicalCertificateConsultationRequest; patientName: string }
>(
    'consultation/send/medical-certificate',
    async ({ referenceId, body, patientName }, { dispatch, rejectWithValue }) => {
        try {
            const response = await sendConsultationMedicalCertificateScript(referenceId, body);
            dispatch(
                setSnackbarMessage({
                    message: `You have successfully sent a medical certificate to ${patientName}. It is issued from ${body.start_date} to ${body.end_date}`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );

            return response;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        'Something went wrong, we could not send the medical certificate to the patient. Please try again.',
                }),
            );

            captureMessage(`[CONSULTATION INFIRMARY] Error sending medical certificate. Error: ${error}`);
            return rejectWithValue(response?.status);
        }
    },
);

export const sendConsultationDoctorLetterThunk = createAsyncThunk<
    ConsultationScriptResponse,
    { referenceId: string; body: DoctorLetterConsultationRequest; patientName: string }
>('consultation/send/doctor-letter', async ({ referenceId, body, patientName }, { dispatch, rejectWithValue }) => {
    try {
        const response = await sendConsultationDoctorLetterScript(referenceId, body);
        dispatch(
            setSnackbarMessage({
                message: `You have successfully sent a doctor letter to ${patientName}.`,
                type: SnackbarTypes.SUCCESS,
            }),
        );

        return response;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                type: SnackbarTypes.ERROR,
                message:
                    response?.reason ??
                    'Something went wrong, we could not send the doctor letter to the patient. Please try again.',
            }),
        );

        captureMessage(`[CONSULTATION INFIRMARY] Error sending doctor letter. Error: ${error}`);
        return rejectWithValue(response?.status);
    }
});

export const sendConsultationPathologyTestThunk = createAsyncThunk<
    ConsultationScriptResponse,
    { referenceId: string; body: PathologyTestConsultationRequest; patientName: string }
>('consultation/send/pathology-test', async ({ referenceId, body, patientName }, { dispatch, rejectWithValue }) => {
    try {
        const response = await sendConsultationPathologyTestScript(referenceId, body);
        dispatch(
            setSnackbarMessage({
                message: `You have successfully sent a pathology request form to ${patientName}.`,
                type: SnackbarTypes.SUCCESS,
            }),
        );

        return response;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                type: SnackbarTypes.ERROR,
                message:
                    response?.reason ??
                    'Something went wrong, we could not send the pathology request form to the patient. Please try again.',
            }),
        );

        captureMessage(`[CONSULTATION INFIRMARY] Error sending pathology request form. Error: ${error}`);
        return rejectWithValue(response?.status);
    }
});

export const updatePatientMedicalProfileThunk = createAsyncThunk<
    MedicalProfileResource,
    { profileId: string; profile: MedicalProfile }
>('doctor/update/medical-profile', async ({ profileId, profile }, { dispatch, rejectWithValue }) => {
    try {
        const medicalProfile = await updateMedicalProfile(profileId, profile);

        dispatch(
            setSnackbarMessage({
                message: `You have successfully updated the patient's medical profile`,
                type: SnackbarTypes.SUCCESS,
            }),
        );

        return medicalProfile;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                type: SnackbarTypes.ERROR,
                message:
                    response?.reason ??
                    "Something went wrong, we could not update the patient's medical profile. Please try again later.",
            }),
        );

        return rejectWithValue(response?.reason);
    }
});

export const sendConsultationSleepStudyThunk = createAsyncThunk<
    ConsultationScriptResponse,
    { referenceId: string; body: SleepStudyRequestConsultation; patientName: string }
>(
    'consultation/send/study-referral-letter',
    async ({ referenceId, body, patientName }, { dispatch, rejectWithValue }) => {
        try {
            const response = await sendConsultationSleepStudyScript(referenceId, body);
            dispatch(
                setSnackbarMessage({
                    message: `You have successfully sent a sleep study referral letter to ${patientName}.`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );

            return response;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        'Something went wrong, we could not send the sleep study referral letter to the patient. Please try again.',
                }),
            );

            captureMessage(`[CONSULTATION INFIRMARY] Error sending sleep study referral letter. Error: ${error}`);
            return rejectWithValue(response?.status);
        }
    },
);

export const sendConsultationMentalHealthCareThunk = createAsyncThunk<
    ConsultationScriptResponse,
    { referenceId: string; body: MentalHealthCareConsultationRequest; patientName: string }
>(
    'consultation/send/mental-health-care-plan',
    async ({ referenceId, body, patientName }, { dispatch, rejectWithValue }) => {
        try {
            const response = await sendConsultationMentalHealthCareScript(referenceId, body);
            dispatch(
                setSnackbarMessage({
                    message: `You have successfully sent a mental health care plan to ${patientName}.`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );

            return response;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        'Something went wrong, we could not send the mental health care plan to the patient. Please try again.',
                }),
            );

            captureMessage(`[CONSULTATION INFIRMARY] Error sending mental health care plan. Error: ${error}`);
            return rejectWithValue(response?.status);
        }
    },
);

export const sendConsultationSpecialistReferralThunk = createAsyncThunk<
    ConsultationScriptResponse,
    { referenceId: string; body: SpecialistReferralConsultationRequest; patientName: string }
>(
    'consultation/send/specialist-referral',
    async ({ referenceId, body, patientName }, { dispatch, rejectWithValue }) => {
        try {
            const response = await sendConsultationSpecialistReferralScript(referenceId, body);
            dispatch(
                setSnackbarMessage({
                    message: `You have successfully sent a specialist referral letter to ${patientName}.`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );

            return response;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        'Something went wrong, we could not send the specialist referral letter to the patient. Please try again.',
                }),
            );

            captureMessage(`[CONSULTATION INFIRMARY] Error sending specialist referral letter. Error: ${error}`);
            return rejectWithValue(response?.status);
        }
    },
);

export const sendConsultationMedicalImagingOrRadiologyThunk = createAsyncThunk<
    ConsultationScriptResponse,
    { referenceId: string; body: MedicalImagingOrRadiologyConsultationRequest; patientName: string }
>(
    'consultation/send/mental-imaging-or-radiology',
    async ({ referenceId, body, patientName }, { dispatch, rejectWithValue }) => {
        try {
            const response = await sendConsultationMedicalImagingOrRadiologyScript(referenceId, body);
            dispatch(
                setSnackbarMessage({
                    message: `You have successfully sent a mental imaging or radiology to ${patientName}.`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );

            return response;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    type: SnackbarTypes.ERROR,
                    message:
                        response?.reason ??
                        'Something went wrong, we could not send the mental imaging or radiology to the patient. Please try again.',
                }),
            );

            captureMessage(`[CONSULTATION INFIRMARY] Error sending mental imaging or radiology. Error: ${error}`);
            return rejectWithValue(response?.status);
        }
    },
);

export const createMedicalRequestNoteThunk = createAsyncThunk<
    MedicalRequestNoteResource,
    { body: MedicalRequestNote; consultationId: string }
>('consultation/create/note', async ({ body, consultationId }, { dispatch, rejectWithValue }) => {
    try {
        const note = await createInfirmaryMedicalRequestNote(body, consultationId);
        dispatch(
            setSnackbarMessage({
                message: `You have successfully saved your consultation notes.`,
                type: SnackbarTypes.SUCCESS,
            }),
        );

        return note;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                type: SnackbarTypes.ERROR,
            }),
        );

        return rejectWithValue(response?.reason);
    }
});

export const uploadConsultationDocumentsThunk = createAsyncThunk<
    ConsultationResourceFile[],
    { body: File[]; category: ConsultationUploadCategory },
    { state: RootState }
>('consultation/documents/general/upload', async ({ body, category }, { dispatch, rejectWithValue, getState }) => {
    try {
        const { consultation } = getState();

        if (
            consultation.currentRequest == null ||
            InfirmaryConsultationRedirectSchema.guard(consultation.currentRequest)
        ) {
            throw new Error('Cannot upload without an active consultation');
        }

        const uploadedFiles = await uploadConsultationDocuments(body, consultation.currentRequest.request.id, category);
        dispatch(
            setSnackbarMessage({
                message: `You have successfully uploaded consultation ${uploadedFiles.length} document${
                    uploadedFiles.length !== 1 ? 's' : ''
                }.`,
                type: SnackbarTypes.SUCCESS,
            }),
        );

        return uploadedFiles;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                type: SnackbarTypes.ERROR,
            }),
        );

        return rejectWithValue(response?.reason);
    }
});

export const uploadConsultationDocumentsViewStateThunk = createAsyncThunk<ConsultationResourceFile, string>(
    'consultation/document/view/state/update',
    async (referenceId, { dispatch, rejectWithValue }) => {
        try {
            return await updateConsultationDocumentViewState(referenceId);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            return rejectWithValue(response?.reason);
        }
    },
);
