/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { endpointFetch, endpointFileFetch } from '../tools/fetch';
import { INFIRMARY_MANAGER_PATH } from '../../../api/endpoints';
import {
    CancelConsultationRequest,
    DoctorLetterConsultationRequest,
    FinalizeConsultationRequest,
    MedicalCertificateConsultationRequest,
    MedicalImagingOrRadiologyConsultationRequest,
    MentalHealthCareConsultationRequest,
    PathologyTestConsultationRequest,
    SleepStudyRequestConsultation,
    SpecialistReferralConsultationRequest,
} from '../../../api/types/infirmary';
import {
    CancelInfirmaryConsultationEndpoint,
    MedicalCertificateRequestEndpoint,
    CreateInfirmaryEScriptEndpoint,
    TakeInfirmaryRequestEndpoint,
    DoctorLetterRequestEndpoint,
    MedicalImagingOrRadiologyRequestEndpoint,
    MentalHealthCareRequestEndpoint,
    PathologyTestRequestEndpoint,
    SleepStudyRequestEndpoint,
    SpecialistReferralRequestEndpoint,
    FinalizeConsultationRequestEndpoint,
    GetHistoryMedicalRequestConsultationEndpoint,
    CreateInfirmaryRequestNoteEndpoint,
    UploadConsultationFileEndpoint,
    UpdateConsultationDocumentViewStateEndpoint,
} from '../../../api/endpoints/infirmary';
import { EScriptRequest } from '../../../api/types/prescription';
import { MedicalRequestNote } from '../../../api/types/medical/request';
import { ConsultationUploadCategory, FILE_UPLOAD_REFERENCE } from '../../../api/constants';

export function takeConsultationMedicalRequest(consultationId: string) {
    return endpointFetch(TakeInfirmaryRequestEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            consultationId,
        },
    });
}

export function getHistoryConsultationMedicalRequest(consultationId: string) {
    return endpointFetch(GetHistoryMedicalRequestConsultationEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            consultationId,
        },
    });
}

export function finalizeConsultationMedicalRequest(requestId: string, body: FinalizeConsultationRequest) {
    return endpointFetch(FinalizeConsultationRequestEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
        body,
    });
}

export function cancelConsultationMedicalRequest(consultationId: string, body: CancelConsultationRequest) {
    return endpointFetch(CancelInfirmaryConsultationEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            consultationId,
        },
        body,
    });
}

export function sendConsultationMedicalCertificateScript(
    requestId: string,
    body: MedicalCertificateConsultationRequest,
) {
    return endpointFetch(MedicalCertificateRequestEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
        body,
    });
}

export function sendConsultationDoctorLetterScript(requestId: string, body: DoctorLetterConsultationRequest) {
    return endpointFetch(DoctorLetterRequestEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
        body,
    });
}

export function sendConsultationPathologyTestScript(requestId: string, body: PathologyTestConsultationRequest) {
    return endpointFetch(PathologyTestRequestEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
        body,
    });
}

export function sendConsultationSleepStudyScript(requestId: string, body: SleepStudyRequestConsultation) {
    return endpointFetch(SleepStudyRequestEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
        body,
    });
}

export function sendConsultationMentalHealthCareScript(requestId: string, body: MentalHealthCareConsultationRequest) {
    return endpointFetch(MentalHealthCareRequestEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
        body,
    });
}

export function sendConsultationSpecialistReferralScript(
    requestId: string,
    body: SpecialistReferralConsultationRequest,
) {
    return endpointFetch(SpecialistReferralRequestEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
        body,
    });
}

export function sendConsultationMedicalImagingOrRadiologyScript(
    requestId: string,
    body: MedicalImagingOrRadiologyConsultationRequest,
) {
    return endpointFetch(MedicalImagingOrRadiologyRequestEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
        body,
    });
}

export function createInfirmaryEScript(requestId: string, body: EScriptRequest) {
    return endpointFetch(CreateInfirmaryEScriptEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
        body,
    });
}

export function createInfirmaryMedicalRequestNote(body: MedicalRequestNote, consultationId: string) {
    return endpointFetch(CreateInfirmaryRequestNoteEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        body,
        params: {
            consultationId,
        },
    });
}

export function uploadConsultationDocuments(
    files: File[],
    consultationId: string,
    category: ConsultationUploadCategory,
) {
    const uploadFormData = new FormData();

    for (const file of files) {
        uploadFormData.append(FILE_UPLOAD_REFERENCE, file);
    }

    return endpointFileFetch(UploadConsultationFileEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        formData: uploadFormData,
        redirect: true,
        params: {
            requestId: consultationId,
            category,
        },
    });
}

export function updateConsultationDocumentViewState(requestId: string) {
    return endpointFetch(UpdateConsultationDocumentViewStateEndpoint, {
        scope: INFIRMARY_MANAGER_PATH,
        params: {
            requestId,
        },
    });
}
