/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export const MENTAL_HEALTH_TREATMENT_PLAN_PRICE = 115;
export const MENTAL_HEALTH_TREATMENT_PLAN_REVIEW_PRICE = 115;

export const MEDICARE_REBATE_VALUE = 103.57;

export enum MedicalHealthService {
    MentalHealthTreatmentPlan = 'Mental Health Treatment Plan',
    MentalHealthTreatmentReview = 'Mental Health Treatment Review',
}
export const MedicalHealthServiceSchema = runtypeFromEnum(MedicalHealthService);

export interface MedicalHealthConsultationType {
    urlSlug: string;
    priceMeta?: string;
    description: string;
    title: MedicalHealthService;
}

export type MedicalHealthType = {
    [key in MedicalHealthService]: MedicalHealthConsultationType;
};
