/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import * as r from 'runtypes';
import { DigitalConsultationSchema } from '.';
import { PathologyServiceTitle, PathologyServiceTitleSchema } from '../constants';
import { ServiceRequestType } from '../constants/checkout';
import { PathologyConsultationDetails } from './consultation';
import { maybe } from '../utils/runtypes';

export const PathologyRequestSchema = r.Record({
    tests: maybe(r.Array(r.String)),
    title: PathologyServiceTitleSchema,
    consultations: r.Array(DigitalConsultationSchema),
    category: r.Literal(ServiceRequestType.Pathology),
});
export type PathologyRequest = r.Static<typeof PathologyRequestSchema>;

export type PathologyServiceConsultationType = {
    [key in PathologyServiceTitle]: PathologyConsultationDetails;
};
