/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 *
 * Description:
 * Using the following tool to generate our color names: http://chir.ag/projects/name-that-color/
 * Colors are documented with a comment describing their usage.
 */

export enum ThemeType {
    LightTheme = 'Light Theme',
    DarkTheme = 'Dark Theme',
}

export interface Theme {
    BrandAccent: string;

    BrandPrimary: string;
    BrandSecondary: string;
    BrandTertiary: string;
    BrandQuaternary: string;
    BrandQuinary: string;
    BrandSenary: string;

    ComplimentaryPrimary: string;
    ComplimentarySecondary: string;
    ComplimentaryTertiary: string;
    ComplimentaryQuaternary: string;
    ComplimentaryQuinary: string;
    ComplimentarySenary: string;

    BackgroundPrimary: string;
    BackgroundSecondary: string;
    BackgroundTertiary: string;
    BackgroundQuaternary: string;
    BackgroundQuinary: string;
    BackgroundSenary: string;

    TextPrimary: string;
    TextSecondary: string;

    BorderPrimary: string;
    BorderSecondary: string;
    BorderTertiary: string;
    BorderQuaternary: string;

    DarkGrey: string;
    BorderHighlight: string; // Jaffa

    SuccessPrimary: string;
    SuccessSecondary: string;
    SuccessTertiary: string;
    SuccessQuaternary: string;
    SuccessQuinary: string;
    SuccessSenary: string;

    ErrorPrimary: string;
    ErrorSecondary: string;
    ErrorTertiary: string;
    ErrorQuaternary: string;
    ErrorQuinary: string;
    ErrorSenary: string;

    BluePrimary: string;
    BlueSecondary: string;
    BlueTertiary: string;
    BlueQuaternary: string;
    BlueQuinary: string;
    BlueSenary: string;

    HighlightPrimary: string;
    HighlightSecondary: string;
    HighlightTertiary: string;
    HighlightQuinary: string;
    HighlightSenary: string;

    BrandDarkColor: string;
    BrandLightColor: string;
    BrandWhite: string;
    WarningPrimary: string;
    WarningSecondary: string;
    WarningTertiary: string;
    WarningQuinary: string;

    SpecialHighlights: string;
}

export const LightTheme: Theme = {
    BrandAccent: '#53389e',

    BrandPrimary: '#5f3cc0',
    BrandSecondary: '#7958ca',
    BrandTertiary: '#9176d3',
    BrandQuaternary: '#b19edf',
    BrandQuinary: '#d0c4ec',
    BrandSenary: '#ede7f7',

    ComplimentaryPrimary: '#416d14',
    ComplimentarySecondary: '#8eb639',
    ComplimentaryTertiary: '#9ec641',
    ComplimentaryQuaternary: '#adcf5f',
    ComplimentaryQuinary: '#bcd77d',
    ComplimentarySenary: '#cfe3a2',

    BackgroundPrimary: '#ffffff',
    BackgroundSecondary: '#f5f5f5',
    BackgroundTertiary: '#f0f0f0',
    BackgroundQuaternary: '#dedede',
    BackgroundQuinary: '#e9ecef',
    BackgroundSenary: '#c8d1d6',

    TextPrimary: '#334664',
    TextSecondary: '#426185',

    DarkGrey: '#838589',

    BorderPrimary: '#dee2e6',
    BorderSecondary: '#ecf0f4',
    BorderTertiary: '#f2f6fa',
    BorderQuaternary: '#f7fbff',

    BorderHighlight: '#f3f3f4',

    SuccessPrimary: '#008800',
    SuccessSecondary: '#009900',
    SuccessTertiary: '#2abb11',
    SuccessQuaternary: '#54c644',
    SuccessQuinary: '#a1dd96',
    SuccessSenary: '#e7f7e5',

    ErrorPrimary: '#cf3131',
    ErrorSecondary: '#e13b37',
    ErrorTertiary: '#f04438',
    ErrorQuaternary: '#eb5451',
    ErrorQuinary: '#e27374',
    ErrorSenary: '#fdcdd2',

    BluePrimary: '#004BE8',
    BlueSecondary: '#2265F5',
    BlueTertiary: '#DDEBFF',
    BlueQuaternary: '#2196F3',
    BlueQuinary: '#90CAF9',
    BlueSenary: '#BBDEFB',

    HighlightPrimary: '#E9A400',
    HighlightSecondary: '#EDB427',
    HighlightTertiary: '#F2C96A',
    HighlightQuinary: '#F7DEA6',
    HighlightSenary: '#FCF2DB',

    BrandDarkColor: '#1F1F1F',
    BrandLightColor: '#ffffff',
    BrandWhite: '#ffffff',
    WarningPrimary: '#fa8100',
    WarningSecondary: '#ffaf49',
    WarningTertiary: '#ffddb0',
    WarningQuinary: '#fff2df',

    SpecialHighlights: '#ECFF00',
};

export const DarkTheme: Theme = {
    BrandAccent: '#53389e',

    BrandPrimary: '#5f3cc0',
    BrandSecondary: '#7958ca',
    BrandTertiary: '#9176d3',
    BrandQuaternary: '#b19edf',
    BrandQuinary: '#d0c4ec',
    BrandSenary: '#ede7f7',

    ComplimentaryPrimary: '#79a22f',
    ComplimentarySecondary: '#8eb639',
    ComplimentaryTertiary: '#9ec641',
    ComplimentaryQuaternary: '#adcf5f',
    ComplimentaryQuinary: '#bcd77d',
    ComplimentarySenary: '#cfe3a2',

    BackgroundPrimary: '#ffffff',
    BackgroundSecondary: '#f5f5f5',
    BackgroundTertiary: '#f0f0f0',
    BackgroundQuaternary: '#dedede',
    BackgroundQuinary: '#e9ecef',
    BackgroundSenary: '#c8d1d6',

    TextPrimary: '#334664',
    TextSecondary: '#8592A9',

    BorderPrimary: '#dee2e6',
    BorderSecondary: '#ecf0f4',
    BorderTertiary: '#f2f6fa',
    BorderQuaternary: '#f7fbff',

    BorderHighlight: '#f3f3f4',

    DarkGrey: '#838589',

    SuccessPrimary: '#008800',
    SuccessSecondary: '#009900',
    SuccessTertiary: '#2abb11',
    SuccessQuaternary: '#54c644',
    SuccessQuinary: '#a1dd96',
    SuccessSenary: '#e7f7e5',

    ErrorPrimary: '#cf3131',
    ErrorSecondary: '#e13b37',
    ErrorTertiary: '#f04438',
    ErrorQuaternary: '#eb5451',
    ErrorQuinary: '#e27374',
    ErrorSenary: '#fdcdd2',

    BluePrimary: '#004BE8',
    BlueSecondary: '#2265F5',
    BlueTertiary: '#DDEBFF',
    BlueQuaternary: '#2196F3',
    BlueQuinary: '#90CAF9',
    BlueSenary: '#BBDEFB',

    HighlightPrimary: '#E9A400',
    HighlightSecondary: '#EDB427',
    HighlightTertiary: '#F2C96A',
    HighlightQuinary: '#F7DEA6',
    HighlightSenary: '#FCF2DB',

    BrandDarkColor: '#1F1F1F',
    BrandLightColor: '#ffffff',
    BrandWhite: '#ffffff',
    WarningPrimary: '#fa8100',
    WarningSecondary: '#ffaf49',
    WarningTertiary: '#ffddb0',
    WarningQuinary: '#fff2df',

    SpecialHighlights: '#ECFF00',
};
