/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import { runtypeFromEnum } from '../utils/runtypes';

export const ARTICLE_DESCRIPTION_CHARACTER_LIMIT = 260;

export enum ArticleCategories {
    AllergiesAndAnaphylaxis = 'Allergies and Anaphylaxis',
    AnxietyAndDepression = 'Anxiety and Depression',
    AsthmaCOPD = 'Asthma/COPD',
    ChildHealthAndImmunizations = 'Child Health And Immunizations',
    ChestInfections = 'Chest Infections',
    ContraceptionsAndPregnancy = 'Contraceptions And Pregnancy',
    FluAndCommonColds = 'Flu And Common Colds',
    GastroEnteritis = 'Gastro-enteritis',
    GeneralHealth = 'General Health',
    GeneralHealthChecks = 'General Health Checks',
    HairLoss = 'HairLoss',
    Headaches = 'Headaches',
    HeartConditions = 'Heart Conditions',
    HeatStroke = 'Heat stroke',
    MenSexualHealth = 'Men Sexual Health',
    Menopause = 'Menopause',
    MedicalCertificates = 'Medical Certificates',
    SexualHealth = 'Sexual Health',
    SkinConditions = 'Skin Conditions',
    SmokingAndVaping = 'Smoking and Vaping',
    StomachIssues = 'Stomach Issues',
    UrinaryTractInfection = 'Urinary Tract Infection (UTI)',
    WeightManagement = 'Weight Management',
}

export const ArticleCategoriesSchema = runtypeFromEnum(ArticleCategories);
