/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://www.expressscripts.com
 */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../utils/runtypes';

export enum SkinReferrals {
    DermatologyForAcne = 'Dermatology Referral For Acne',
    DermatologyForGeneralSkinCheck = 'Dermatology referral for general skin',
}
const SkinReferralsSchema = runtypeFromEnum(SkinReferrals);

export enum EyesReferrals {
    OphthalmologistForAgeRelatedMacularDegeneration = 'Ophthalmologist Referral For Age-Related Macular Degeneration',
    OphthalmologistForDiabetes = 'Ophthalmologist Referral For Diabetes',
    OphthalmologistForCataracts = 'Ophthalmologist Referral For Cataracts',
    OphthalmologistForGlaucoma = 'Ophthalmologist Referral For Glaucoma',
}
const EyesReferralsSchema = runtypeFromEnum(EyesReferrals);

export enum ColonoscopyReferrals {
    GastroenterologyForInitialScreen = 'Gastroenterology (Colonoscopy) Referral For Initial Screen',
    GastroenterologyForCheckUp = 'Gastroenterology (Colonoscopy) Referral For 3–5-Year Check-Up',
}
const ColonoscopyReferralsSchema = runtypeFromEnum(ColonoscopyReferrals);

export const SpecialistReferralServiceTitleSchema = SkinReferralsSchema.Or(EyesReferralsSchema).Or(
    ColonoscopyReferralsSchema,
);
export type SpecialistReferralServiceTitle = r.Static<typeof SpecialistReferralServiceTitleSchema>;

export interface SpecialistReferralConsultationType {
    urlSlug: string;
    description: string;
    title: SpecialistReferralServiceTitle;
}

export type SkinReferralsType = {
    [key in SkinReferrals]: SpecialistReferralConsultationType;
};

export type EyesReferralsType = {
    [key in EyesReferrals]: SpecialistReferralConsultationType;
};

export type ColonoscopyReferralsType = {
    [key in ColonoscopyReferrals]: SpecialistReferralConsultationType;
};
